import React from "react";
import ReactFullpage from '@fullpage/react-fullpage';


// IMPORT BLOCKS OF MAINPAGE 
import HeadBlock from "./HeadBlock"
import ProjectsBlockOld from "./Projectsblockold"
import ProjectsSmrtph from "./ProjectsSlider"
import ExpertBlock from "./ExpertBlock"
import ServiceBlock from "./ServiceBlock"
import EquipmentBlock from "./EquipmentBlock"
// import PartnersBlock from "./PartnersBlock" => Был блок 
// "Прямые контракты" с логотипами брендов, но после санкций не сотрудничаем и блок заменили на статьи.
import ArticlesBlock from "./ArticlesBlock";


// const pluginWrapper = () => {
//   require ('../../statics/fullpage.scrollHorizontally.min.js');
// };

class MainPageTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSlider: 0
        }

        this.updateIndex = this.updateIndex.bind(this);
    }

    updateIndex(index){
        this.setState({
            activeSlider: index
        });
    }

    hideRounds() {
        let videobtn = document.getElementById("btnvid");
        let phonebtn = document.getElementById("phonebtn");
        let contactbtn = document.getElementById("mail");
        videobtn.style.display = 'none';
        phonebtn.style.display = 'none';
        contactbtn.style.display = 'none';
    }

    showRounds() {
        let videobtn = document.getElementById("btnvid");
        let phonebtn = document.getElementById("phonebtn");
        let contactbtn = document.getElementById("mail");
        videobtn.style.display = 'block';
        phonebtn.style.display = 'block';
        contactbtn.style.display = 'block';
    }

    // HideVideoBtn() {
    //     let videoadapt = document.getElementById("btnvidadaptive");
    //     videoadapt.style.display = 'none';
    // }

    // ShowVideoBtn(){
    //     let videoadapt = document.getElementById("btnvidadaptive");
    //     videoadapt.style.display = 'block';
    // }

    render(){
        // const {activeSlider} = this.state;
        return (
            <ReactFullpage
                // pluginWrapper = {pluginWrapper}
                licenseKey = {'F7426780-B4304556-9B0C6DED-6E2A3707'}
                scrollHorizontally = {false}
                scrollHorizontallyKey = 'cmVmZW5nLnJ1X0E2bGMyTnliMnhzU0c5eWFYcHZiblJoYkd4NTV6bA=='
                scrollingSpeed = {1000}
                controlArrows = {false}
                fitToSection = {true}
                anchors = {['page1', 'page2', 'page3', 'page4', 'page5', 'page6']}
                onLeave = {(origin, destination, direction) => {
                    if(destination.index === 0){
                        return this.props.showLightTop();
                    }
                    if(destination.index === 1){
                        return this.props.showDarkTop();
                    }
                    if(destination.index === 2){
                        return this.props.showLightTop();
                    }
                    if(destination.index === 3){
                        return this.props.showDarkTop();
                    }
                    if(destination.index === 4){
                        return this.props.showLightTop();
                    }
                    if(destination.index === 5){
                        return this.props.showDarkTop();
                    }
                }}
                afterLoad = {(origin, destination, direction) => {
                    if(destination.index === 5){
                        return this.hideRounds();
                    }
                    if(destination.index === 4){
                        return this.showRounds();
                    }
                    // if(destination.index === 1){
                    //     return this.HideVideoBtn();
                    // }
                    // if(destination.index === 0){
                    //     return this.ShowVideoBtn();
                    // }
                }}
                onSlideLeave = {(section, origin, destination, direction) => {
                    this.updateIndex(destination.index);
                }}
                afterSlideLoad = {(section, origin, destination, direction) => {
                    // this.updateIndex(destination.index);
                    // https://github.com/alvarotrigo/fullPage.js/tree/master/lang/russian#fullpagejs
                }}

                render = {({ state, fullpageApi}) => {
                    return (
                        
                        <ReactFullpage.Wrapper>
                            <div id="fullpage-wrapper">
                                <div className="section">
                                    <HeadBlock />
                                </div>
                                <div className="section">
                                   <ProjectsBlockOld />
                                   <ProjectsSmrtph />
                                </div>
                                <div className="section" id="expert">
                                    <ExpertBlock />
                                </div>
                                <div className="section">
                                    <ServiceBlock />
                                </div>
                                <div className="section" id="equipment">
                                    <EquipmentBlock />
                                </div>
                                <div className="section">
                                    <ArticlesBlock />
                                </div>
                            </div>
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        )
    }

}

export default MainPageTemplate;