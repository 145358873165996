import React from "react"
// import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
// import RefOrderBtn from "../../../Elements/Buttons/RefButtons/RefStyledButtons/RefOrder"

const VolumesPromo = () => {
    const title = <h3 className="promo">Емкости буферные</h3>
  
  return (
    <Container fluid>
        <Row style={{textAlign: "center"}}>
            <Col>
                {title}
                <img className="promo-image" style={{ padding: "2vw"}} src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/main/volumes.webp" alt="kkb" />
                {/* <RefOrderBtn style={{margin: "0 auto"}}>
                    <Link to="/equipment/kkb#" className="buttonlink">ПОДРОБНЕЕ</Link>
                </RefOrderBtn> */}
            </Col>
        </Row>
    </Container>

)
}


export default VolumesPromo;