import React from 'react';
import Services from "./Services";
import ServicesAdaptive from "./ServicesAdaptive"

const ServiceBlock = () => {
    return(
        <div className="mainpage-block-container">
            <h2>Мы возьмем на себя</h2>
            <Services /> 
            <ServicesAdaptive />       
        </div>
    )
}

export default ServiceBlock