import React from 'react';

import EquipmentView from "./Equipment"

export default function Partners() {

    return(
        <>
            <EquipmentView />
        </>
    )
}