import React from "react";

const SVG = ({
  style = {},
  fillcls1 = "#e0e0e0",
  fillcls2 = "#fff",
  fillcls3 = "#27a4de",
  fillcls4 = "none",
  fillrule = 'evenodd',
  stroke = '#27a4de',
  strokeMiterlimit = '10',
  strokeWidth = '2px',
  width = "",
  height = "",
  className = "",
  viewBox = "0 0 425.2 354.33"
}) => (
  <div className="service-icon">
    <svg 
      id = 'construct'
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      type = "image/svg+xml">
      {/* <defs><style>.cls-1{fill:#e0e0e0;}.cls-2{fill:#fff;}.cls-3{fill:#27a4de;}.cls-4{fill:none;stroke:#27a4de;stroke-miterlimit:10;stroke-width:2px;}</style></defs> */}
      <title>03</title>
      <path fill={fillcls1} d="M233.28,55.25l148-1v36l-43,46s46,8,50,42-6,47-6,47-13,27-49,35c-26.54,5.9-70.81-2.55-80-6-8-3-10-3-16-7-4.3-2.87-11.67-7.73-14-11l20-36s42.08,21,71.89,12.9a24.64,24.64,0,0,0,18.15-19.55c1.08-6.65-.84-12.52-9-16.35-15-7-47-4-47-4v-36l36-39h-80Z"/>
      <ellipse fill={fillcls1} cx="127.28" cy="159.25" rx="93.5" ry="103.5"/>
      <ellipse fill={fillcls2} cx="126.78" cy="158.25" rx="38.5" ry="57"/>
      <circle fill={fillcls3} cx="190.28" cy="260.25" r="6"/>
      <line fill={fillcls4} stroke = {stroke} strokeWidth={strokeWidth} strokeMiterlimit={strokeMiterlimit} x1="140.78" y1="186.75" x2="162.78" y2="212.75"/>
      <line fill={fillcls4} stroke = {stroke} strokeWidth={strokeWidth} strokeMiterlimit={strokeMiterlimit} x1="132.78" y1="192.75" x2="162.78" y2="229.75"/>
      <circle fill={fillcls4} stroke = {stroke} strokeWidth={strokeWidth} strokeMiterlimit={strokeMiterlimit} cx="132.28" cy="184.25" r="9"/>
      <rect fill={fillcls3} x="204.78" y="141.75" width="13" height="27"/>
      <line fill={fillcls4} stroke = {stroke} strokeWidth={strokeWidth} strokeMiterlimit={strokeMiterlimit} x1="135.78" y1="175.75" x2="205.78" y2="142.75"/>
      <line fill={fillcls4} stroke = {stroke} strokeWidth={strokeWidth} strokeMiterlimit={strokeMiterlimit} x1="141.78" y1="183.75" x2="205.78" y2="153.75"/>
      <path fill={fillcls3} d="M160.78,219.63v9.93l19.56,24.13a12.28,12.28,0,0,1,4.71-4.13Z"/>
      <path fill={fillcls3} d="M179.1,173.67l-18.32,9.08v30.52l28.42,35.06a11.59,11.59,0,0,1,1.33-.08c.26,0,.52,0,.79,0v0l5-2.94V215.64h11.95l.09,34.89H265v10.72H202.74a12.24,12.24,0,0,1-24.46-.75,11.43,11.43,0,0,1,.07-1.32L160.78,237.5v41.25H287.1V173.67Zm94.68,17.08v55h-59v-60h42v5h-15v11h-5v-11h-17v24h17v-8h5v8h7v5h-7v7h-5v-7h-17v21h17v-7h5v7h27v-21h-15v-5h15v-24h-7v-5h12Z"/>
    </svg>
    <svg version="1.1" id="service-leftarr-01" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-20 -18 50 50" fill= '#27a4de' >
        <path d="M26.485,21.206c0.429-0.444,0.429-1.143,0-1.587c-0.444-0.429-1.159-0.429-1.587,0l-8.047,8.047
        V1.111C16.851,0.492,16.359,0,15.74,0c-0.619,0-1.127,0.492-1.127,1.111v26.555l-8.031-8.047c-0.444-0.429-1.143-0.429-1.587,0
        c-0.429,0.444-0.429,1.143,0,1.587l9.952,9.952c0.429,0.429,1.143,0.429,1.587,0L26.485,21.206z"/>
    </svg>
  </div>

);

export default SVG;