import React, { useRef, useState } from 'react';
import {Container, Row, Col} from "react-bootstrap";
// Buttons 
import CentralsButton from "../../Elements/Buttons/RefButtons/EquipmentButtons/centrals"
import KkbsBtn from "../../Elements/Buttons/RefButtons/EquipmentButtons/kkbs"
import ChillerBtn from "../../Elements/Buttons/RefButtons/EquipmentButtons/chillers"
import WaterChillersButton from "../../Elements/Buttons/RefButtons/EquipmentButtons/waterchillers"
import PumpsButton from "../../Elements/Buttons/RefButtons/EquipmentButtons/pumps"
import IceaccusButton from "../../Elements/Buttons/RefButtons/EquipmentButtons/iceaccus"
import ClimatButton from "../../Elements/Buttons/RefButtons/EquipmentButtons/climatblocks"
import ContainerButton from "../../Elements/Buttons/RefButtons/EquipmentButtons/containers"
import VolumesButton from "../../Elements/Buttons/RefButtons/EquipmentButtons/volumes"

// Catalog
import CentralPromo from "./Catalog/centralpromo";
import KkbPromo from "./Catalog/kkbpromo";
import ChillerPromo from "./Catalog/chillerpromo";
import WaterchillerPromo from "./Catalog/waterchillerspromo";
import PumpsPromo from "./Catalog/pumpspromo";
import IceaccumPromo from "./Catalog/iceaccumpromo";
import ClimatblockPromo from "./Catalog/climatblockpromo";
import ContainersPromo from "./Catalog/containerspromo";
import VolumesPromo from "./Catalog/volumespromo";

export default function EquipmentView() {
    const [promo, setPromo] = useState(CentralPromo);
    const updatePromoImageRef = useRef();

    // useEffect(() => {
    //     if (promo === CentralPromo) {
    //       return;
    //     }
    //     updatePromoImageRef.current.animate(
    //       {
    //         opacity: [0, 0]
    //       },
    //       50
    //     );
    //   }, [promo]);


    function handleClick(promo) {
            setPromo(promo);
    }

    return(
        <>
            <Container fluid className="mainpage-block-container">
                <Row  className="promo-block">
                    <Col xl={5} lg={6} md={6}>
                        <h2 
                        style={{
                            color: '#00a0e3', 
                            textAlign: "left",
                            position: "relative",
                            right: "0",
                            left: "1vw",
                            top: "2vh",
                        }}>
                            мы производим
                        </h2>
                        <Row style={{ position: "relative", right: "0", top: "3vh", left: "-1vw" }}>
                            <Col className="btn-squared-block" onClick={()=> handleClick(CentralPromo) } xl={4} lg={4} md={4} sm={4} xs={4} style={{marginBottom: "1vh"}}>
                                <CentralsButton />
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(KkbPromo) } xl={4} lg={4} md={4} sm={4} xs={4} style={{marginBottom: "1vh"}}>
                                <KkbsBtn />
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(ChillerPromo) } xl={4} lg={4} md={4} sm={4} xs={4} style={{marginBottom: "1vh"}}>
                                <ChillerBtn />
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(WaterchillerPromo) } xl={4} lg={4} md={4} sm={4} xs={4} style={{marginBottom: "1vh"}}>
                                <WaterChillersButton />
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(PumpsPromo) } xl={4} lg={4} md={4} sm={4} xs={4} style={{marginBottom: "1vh"}}>
                                <PumpsButton />
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(IceaccumPromo) } xl={4} lg={4} md={4} sm={4} xs={4} style={{marginBottom: "1vh"}}>
                                <IceaccusButton />
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(ClimatblockPromo) } xl={4} lg={4} md={4} sm={4} xs={4} style={{marginBottom: "1vh"}}>
                                <ClimatButton />
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(ContainersPromo) } xl={4} lg={4} md={4} sm={4} xs={4} style={{marginBottom: "1vh"}}>
                                <ContainerButton />
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(VolumesPromo) } xl={4} lg={4} md={4} sm={4} xs={4} style={{marginBottom: "1vh"}}>
                                <VolumesButton />
                            </Col>
                        </Row>
                    </Col>
                    
                    <Col xl={7} lg={6} md={6}>
                        <div ref={updatePromoImageRef} style={{ opacity: 1 }}>
                            {promo}
                        </div>
                    </Col>
                    
                </Row>
            </Container>
            
        </>
    )
}