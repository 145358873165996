import React from "react"

import {Button} from "react-bootstrap"
import PumpsIcon from "../../../Icons/EquipmentIcons/05-pumps.svg"

const PumpsButton = () => (

  <>
    <Button variant="eq-squared" size="xxl">
      <img src={PumpsIcon} className="equipment-btn-icon" alt="Оборудование агрегаты компрессорно испарительные" />
    </Button>
    <p style={{fontSize: "14px"}}>Агрегаты<br/>насосные</p>
    <p style={{fontSize: "14px"}}></p>
    <p style={{fontSize: "14px"}}></p>
  </>
)

export default PumpsButton