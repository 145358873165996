import React from "react";
import ProjectSlider from "./ProjectSlider"


const ProjectsBlock = () => (
<>
    <ProjectSlider />
</>
)

export default ProjectsBlock