import React from "react"

import {Button} from "react-bootstrap"
import KkbsIcon from "../../../Icons/EquipmentIcons/02-kkbs.svg"

const KkbsButton = () => (

  <>
    <Button variant="eq-squared" size="xxl">
      <img src={KkbsIcon} className="equipment-btn-icon" alt="Оборудование агрегаты компрессорно испарительные" />
    </Button>
    <p style={{fontSize: "14px"}}>Агрегаты</p>
    <p style={{fontSize: "14px"}}>компрессорно-</p>
    <p style={{fontSize: "14px"}}>конденсаторные</p>
  </>
)

export default KkbsButton