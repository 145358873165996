import React from "react";

const SVG = () => (
  <div className="service-icon-top">
    <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.2 354.33" width = "100%">
      <title>01</title>
      <ellipse class="cls-1" fill= '#e0e0e0' cx="151.75" cy="172.5" rx="93.5" ry="103.5"/>
      <polygon class="cls-1" fill= '#e0e0e0' points="252.75 69.5 252.75 113.5 290.75 113.5 290.75 276.5 349.75 276.5 349.75 69.5 252.75 69.5"/>
      <ellipse class="cls-2" fill= '#fff' cx="152.75" cy="172" rx="38.5" ry="57"/>
      <polygon class="cls-3" fill= '#27a4de' points="147.75 234.5 147.75 247 208.75 296 208.75 265 147.75 234.5"/>
      <polygon class="cls-3" fill= '#27a4de' points="147.75 219.5 208.75 245.5 208.75 257.5 147.75 228.5 147.75 219.5"/>
      <polygon class="cls-3" fill= '#27a4de' points="147.75 204.5 208.75 225.5 208.75 238.5 147.75 213.5 147.75 204.5"/>
      <polygon class="cls-3" fill= '#27a4de' points="147.75 198.5 147.75 187.5 188.75 157.5 241.75 167.5 208.75 207.5 208.75 218.5 147.75 198.5"/>
      <polygon class="cls-3" fill= '#27a4de' points="245.25 167.92 213.75 207.5 213.75 295.5 233.75 284.5 233.75 229.5 233.75 228.5 267.75 209.5 267.75 267.5 281.75 258.5 281.75 185.5 245.25 167.92"/>
      <line class="cls-4" fill= 'none' x1="239.75" y1="246.5" x2="239.75" y2="258.5"/>
      <polygon class="cls-3" fill= '#27a4de' points="285.75 211.5 285.75 222.5 319.75 222.5 285.75 211.5"/>
      <polygon class="cls-3" fill= '#27a4de' points="285.75 226.5 285.75 258.5 319.75 258.5 319.75 225.5 285.75 225.5 285.75 226.5"/>
      <rect class="cls-2" fill= '#fff' x="295.75" y="229.5" width="11" height="12"/>
      <line class="cls-4" fill= 'none' x1="301.75" y1="229.5" x2="301.75" y2="241.5"/>
    </svg>
  </div>

);

export default SVG;
