import React from "react";
import HeadCarousel from "./HeadCarousel"


const HeadSlider = () => (
<>
    <HeadCarousel />
</>
)

export default HeadSlider