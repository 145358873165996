import React from 'react';
import LayoutModal from '../../../Modules/ContactForms/ContactUs/ModalWindow'
// import {Link} from 'gatsby'

import AtSignButton from '../../Icons/ElementIcons/atsignbutton.svg'


export default function LayoutContact() {
    const [layoutModalShow, setLayoutModalShow] = React.useState(false);
  
	return (
        <>
                <button id="mail" className="message-learn-more" onClick={() => setLayoutModalShow(true)}>
                    <div className="messagecircle">
                        <div className="messagetext">
                                СВЯЗАТЬСЯ С НАМИ
                        </div>
                    </div>
                    <div className="message-button-text" >
                        <img src = {AtSignButton} alt=''/>
                    </div>
                </button>

                <LayoutModal
                    show={layoutModalShow}
                    onHide={() => setLayoutModalShow(false)}
                />
        </>
	);
  };