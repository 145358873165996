import React from "react"
// import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
// import RefOrderBtn from "../../../Elements/Buttons/RefButtons/RefStyledButtons/RefOrder"

const CentralPromo = () => {
    const title = <h3 className="promo">Агрегаты компрессорные</h3>
  
  return (
    <Container fluid>
        <Row style={{textAlign: "center"}}>
            <Col>
                {title}
                <img className="promo-image" style={{ padding: "2vw"}} src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/main/CentralOZMK.webp" alt="Агрегаты компрессорные Рефинжиниринг" />

                    
            </Col>
        </Row>
    </Container>

)
}


export default CentralPromo;