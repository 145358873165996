import React from "react";

const SVG = ({
  style = {},
  fillcls1 = "#e0e0e0",
  fillcls2 = "#fff",
  fillcls3 = "#27a4de",
  fillcls4 = "none",
  fillrule = 'evenodd',
  stroke = '#27a4de',
  strokeMiterlimit = '10',
  strokeWidth = '3px',
  width = "",
  height = "",
  className = "",
  viewBox = "0 0 425.2 354.33"
}) => (
  <div className="service-icon">
    <svg 
        id = 'management'
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        type = "image/svg+xml">
      {/* <defs><style>.cls-1{fill:#e0e0e0;}.cls-2{fill:#fff;}.cls-3{fill:#27a4de;}.cls-4{fill:none;stroke:#27a4de;stroke-miterlimit:10;stroke-width:3px;}</style></defs> */}
      <title>05</title>
      <ellipse fill={fillcls1} cx="123.75" cy="160.29" rx="93.5" ry="103.5"/>
      <ellipse fill={fillcls2} cx="124.75" cy="159.79" rx="38.5" ry="57"/>
      <path fill={fillcls1} d="M251.25,56.79l-9,116h63.52a39,39,0,0,1,18,4.25c6.25,3.25,12.5,8.75,12.5,17.75,0,9.5-6.69,14.83-13,17.73a37.81,37.81,0,0,1-16,3.34c-12.25-.08-43.65-1.75-58-16.07l-21,42s50.17,34.5,111,18.11c18.44-5,34.76-16.16,45.08-32.23,9.69-15.09,13-31.47,9-48.35-5.7-23.86-24.93-42.09-48.82-47.67a177.59,177.59,0,0,0-47.21-3.86l1-28h83l1-43Z"/>
      <path fill={fillcls3} d="M136.75,285.29l-17-50s26-4,30-11,15-21,33-21l4,10s-9,3-9,5,33-8,34-10l5,10-21,11,1,3,21-11,3,9-23,11v3l23-11v10l-21,10v3l22-11v11s-32,14-35,15C183.9,272.24,136.75,281.29,136.75,285.29Z"/>
      <path fill={fillcls3} d="M189.59,216.17s-5.84-18.88-1.84-24.88,15.14,20.79,15.14,20.79l-6.14,6.21Z"/>
      <path fill={fillcls3} d="M205.58,263.33s.17,6,6.17,5a4.92,4.92,0,0,0,.78-.18c5.08-1.59,3.74-9.43,3.74-9.43l-2.52-3.39Z"/>
      <path fill={fillcls4} stroke = {stroke} strokeWidth={strokeWidth} strokeMiterlimit={strokeMiterlimit} d="M211.25,267.79s-3.52,20.56-9.58,27.92a5.13,5.13,0,0,1-7.59.29c-3-3-5.83-8.2-7.33-12.71-2-6-17-4-21,6"/>
      <path fill={fillcls4} stroke = {stroke} strokeWidth={strokeWidth} strokeMiterlimit={strokeMiterlimit} d="M190.25,193.79c0,.41-10.62-11.86-8.5-24.5.82-4.93,8.32-18.64,14.5-22.5"/>
      <polygon fill={fillcls3} points="198.75 151.29 189.75 144.29 204.75 131.29 208.75 134.29 198.75 151.29"/>
      <path fill={fillcls3} d="M232.75,104.29s-32.88,15.09-25.94,28.55C206.75,133.29,219.75,140.29,232.75,104.29Z"/>
    </svg>
  </div>

);

export default SVG;