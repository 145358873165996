import React from "react"

import {Button} from "react-bootstrap"
import IceaccusIcon from "../../../Icons/EquipmentIcons/06-iceaccus.svg"

const IceaccusButton = () => (

  <>
    <Button variant="eq-squared" size="xxl">
      <img src={IceaccusIcon} className="equipment-btn-icon" alt="Оборудование агрегаты компрессорно испарительные" />
    </Button>
    <p style={{fontSize: "14px"}}>Льдоаккумуляторы</p>
    <p style={{fontSize: "14px"}}></p>
    <p style={{fontSize: "14px"}}></p>
  </>
)

export default IceaccusButton