import React from "react";

const cardArticle = ({src, alt, title, text, link}) => (
    <>
        <div className="articlecard-wrapper">
            <div className="articlecard-img">
                <img src={src} alt={alt}/>
            </div>
            <div className="articlecard-body">
                <h5>{title}</h5>
                <p>{text}</p>
                {/* <span><a href={link}><button className="articlecard-button">ПОДРОБНЕЕ</button></a></span> */}
                <span><a href={link}>Подробнее...</a></span>
            </div>
        </div>
    </>
)

export default cardArticle;