import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
// import RefOrderBtn from "../../../Elements/Buttons/RefButtons/RefStyledButtons/RefOrder"
import RefAnimaBtn from "../../../Elements/Buttons/RefButtons/RefStyledButtons/RefAnimaBtn"

const ClimatblockPromo = () => {
    const title = <h3 className="promo">Климатические блоки</h3>
  
  return (
    <Container fluid>
        <Row style={{textAlign: "center"}}>
            <Col>
                {title}
                <img className="promo-image" style={{ padding: "2vw"}} src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/main/ClimoblockGjel.webp" alt="kkb" />
                <RefAnimaBtn style={{margin: "0 auto"}}>
                    <Link to="/equipment/klimablock" className="buttonlink">ПОДРОБНЕЕ</Link>
                </RefAnimaBtn>
                {/* <RefOrderBtn style={{margin: "0 auto"}}>
                    <Link to="/equipment/kkb#" className="buttonlink">ПОДРОБНЕЕ</Link>
                </RefOrderBtn> */}
            </Col>
        </Row>
    </Container>

)
}


export default ClimatblockPromo;