import React from 'react';
import ProjectsBlockBody from "./ProjectsBlock"


const ProjectsBlockOld = () => {
    return(
        <>
          <ProjectsBlockBody />  
        </>     
    )
}

export default ProjectsBlockOld