import React from 'react';

import ExpertizeMod from "./ExpertizeMod"

export default function Partners() {

    return(
        <>
            <ExpertizeMod />
        </>
    )
}