import React, {useState} from 'react';
import {Link} from 'gatsby'
import {Fade} from "react-reveal"

import Icon from "./Icons"

import {Container, Row, Col} from 'react-bootstrap'


function Services() {
    const [servicetext, setText] = useState(<Text1 />)

    return(
        <Container fluid className="service-block-adaptive">
            <Row>
                <Col xl={3} lg={3} md={4} sm={4} xs={4} className="service-icons-grid">
                    <button onClick = {() => {setText(<Text1 />)}}>
                            <Icon name="concept" />
                    </button>
                </Col>
                <Col xl={3} lg={3} md={4} sm={4} xs={4} className="service-icons-grid">
                    <button onClick = {() => {setText(<Text2 />)}}>
                        <Icon name="economy" />
                    </button>
                </Col>
                <Col xl={3} lg={3} md={4} sm={4} xs={4} className="service-icons-grid">
                    <button onClick = {() => {setText(<Text3 />)}}>
                            <Icon name="construct" />
                    </button>
                </Col>
            </Row>
            <Row>


                <Col xl={3} lg={3} md={4} sm={4} xs={4} className="service-icons-grid">
                    <button onClick = {() => {setText(<Text4 />)}}>
                            <Icon name="production" />
                    </button>
                </Col>
                <Col xl={3} lg={3} md={4} sm={4} xs={4} className="service-icons-grid">
                    
                <button onClick = {() => {setText(<Text5 />)}}>
                        <Icon name="management" />
                </button>
                </Col>
                <Col xl={3} lg={3} md={4} sm={4} xs={4} className="service-icons-grid">
                    <button onClick = {() => {setText(<Text6 />)}}>
                            <Icon name="service" />
                    </button>
                </Col>
            </Row>
            <Row>
                <Col md={1} sm={1} xs={1}></Col>
                <Col xl={6} lg={6} md={10} sm={10} xs={10} className="service-text">
                    {servicetext}
                </Col>
                <Col md={1} sm={1} xs={1}></Col>
            </Row>
        </Container>
    )
}


const Text1 = () => {
    return(
        <Fade duration = {2000}>
            <div>
                <h3>От идеи до готовой концепции</h3>
                <p>Воплотим идеи заказчика в концепцию, оптимизируем работу существующего предприятия. Наш опыт и знания — это простые, надежные и эффективные инструменты, которые гарантируют результат.</p>
                <Link to = "/kontzeptziya-proekta">
                    <span>ПОДРОБНЕЕ</span>
                </Link>
            </div>
        </Fade>

    )
}

const Text2 = () => {
    return(
        <Fade duration = {2000}>
            <div>
                <h3>Предварительный экспертный расчет</h3>
                <p>Проведем комплексную оценку капитальных и эксплуатационных затрат. Определим оптимальные технические решения и сроки окупаемости.</p>
                <Link to = "/teh-obosnovanie">
                    <span>ПОДРОБНЕЕ</span>
                </Link>
            </div>

        </Fade>
    )
}

const Text3 = () => {
    return(
        <Fade duration = {2000}>
            <div className='showtext3'>
              <h3>Разработка полного комплекта документации</h3>
                <p>Выполняем проектирование как отдельных разделов, так и полного комплекта, включающего в себя:</p>
                <ul>
                    <li>Предпроектная документация (стадия «Буклет»);</li>
                    <li>Проектная документация в соответствии с Постановлением №87 от 21.12.2020г.;</li>
                    <li>Рабочая документация в соответствии с ГОСТ Р 21.101-2020г. и ГОСТ 2.102-2013г.;</li>
                    <li>Проектирование опасных производственных объектов (ОПО);</li>
                    <li>Проведение экспертизы промышленной безопасности;</li>
                    <li>Подготовка документации для постановки на учёт в Ростехнадзор.</li>
                </ul>
                <Link to = "/oformlenie-dokumentatzii">
                    <span>ПОДРОБНЕЕ</span>
                </Link>
            </div> 
        </Fade>
    )
}

const Text4 = () => {
    return(
        <Fade duration = {2000}>
            <div>
                <h3>Свое производство</h3>
                <p>Конструирование и полный производственный цикл промышленного холодильного оборудования. Любые нестандартные задачи реализуются с высоким качеством не уступающим европейским аналогам.</p>
                    <Link to = "/proizvodstvo-oborudovaniya">
                     <span>ПОДРОБНЕЕ</span>
                    </Link>
              </div> 

        </Fade>
    )
}

const Text5 = () => {
    return(
        <Fade duration = {2000}>
            <div>
                <h3>Комплексное планирование</h3>
                <p>Управление проектом является одним из ключевых моментов реализации проекта. Мы основательно подходим к его управлению и используем современные EPCm инструменты.</p>
                    <Link to = "/upravlenie-proektom">
                        <span>ПОДРОБНЕЕ</span>
                    </Link>
            </div> 
        </Fade>
    )
}

const Text6 = () => {
    return(
        <Fade duration = {2000}>
            <div>
                <h3>Круглосуточная сервисная служба</h3>
                <p>Выполняем пусконаладочные работы, гарантийные обязательства и техническое обслуживание 24/7/365. Работаем с промышленными холодильными системами любого уровня сложности и автоматизации.</p>
                    <Link to = "/service-guarantee">
                        <span>ПОДРОБНЕЕ</span>
                    </Link>
            </div> 
        </Fade>
    )
}

export default Services