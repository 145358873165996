import React from 'react';
import { Fade } from 'react-reveal';

const RefAnimaBtn = ({children}) => {
    return (
        <div>
            <Fade duration={3000}>
                    <div className="allprojectsbuttonbig">
                        <button id="connect-button" className="ref-button equipment-button">
                            {children}
                        </button>
                    </div>
            </Fade>
        </div>
    )
}

export default RefAnimaBtn;