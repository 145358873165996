import React from 'react';
import FooterBlock from "./FooterBlock"

const Footer = () => {
    return(
        <>
            <FooterBlock />        
        </>
    )
}

export default Footer