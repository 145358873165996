import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
// import RefOrderBtn from "../../../Elements/Buttons/RefButtons/RefStyledButtons/RefOrder"
import RefAnimaBtn from "../../../Elements/Buttons/RefButtons/RefStyledButtons/RefAnimaBtn"

const KkbPromo = () => {
    const title = <h3 className="promo">Компрессорно-конденсаторные агрегаты</h3>
  
  return (
    <Container>
        <Row style={{textAlign: "center"}}>
            <Col>
                {title}
                <img className="promo-image" style={{ padding: "2vw"}} src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/main/kk_nalchik_opened.webp" alt="kkb" />
                <RefAnimaBtn style={{margin: "0 auto"}}>
                    <Link to="/equipment/kkb" className="buttonlink">ПОДРОБНЕЕ</Link>
                </RefAnimaBtn>
                <Link to="/equipment/kkb#" className="buttonlinkadaptive">ПОДРОБНЕЕ</Link>
            </Col>
        </Row>
    </Container>

)
}


export default KkbPromo;