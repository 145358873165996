import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "gatsby";

const FooterMenuBlock = () => {
    return(
        <Container fluid>
            <Row>
                <Col className="footermenu">
                <ul>
                    {/* <li><Link to = '/services'>Услуги</Link></li> */}
                    <li style={{cursor: 'pointer'}}><Link to = '/projects' >Проекты</Link></li>
                    <li style={{cursor: 'pointer'}}><a href = 'https://drive.google.com/file/d/1ouXO-gdZ1mdsAfwkWJtPLraBAZgwr0q4/view' target="_blank" rel="noopener noreferrer">Презентация компании</a></li>
                    <li style={{cursor: 'pointer'}}><a href = 'https://drive.google.com/file/d/1WaL-ZavSPPHseVv5b1lE1ila1qFoRswl/view?usp=sharing' target="_blank" rel="noopener noreferrer">Сертификаты СРО</a></li>
                    <li style={{cursor: 'pointer'}}><a href = '/sout'>СОУТ</a></li>
                    <li style={{cursor: 'pointer'}}><Link to = '/offer'>Вакансии</Link></li>
                    <li style={{cursor: 'pointer'}}><Link to = '/contacts'>Контакты</Link></li>
                </ul>
                </Col>
            </Row>
        </Container>
    )
}

export default FooterMenuBlock