import React from "react";

const SVG = () => (
  <div className="service-icon">
    <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.2 354.33">
      {/* <defs><style>.cls-1{fill:#e0e0e0;}.cls-2{fill:#fff;}.cls-3{fill:#27a4de;}</style></defs> */}
      <title>04</title>
      <ellipse class="cls-1" fill= '#e0e0e0' cx="104.35" cy="146.17" rx="93.5" ry="103.5"/>
      <ellipse class="cls-2" fill= '#fff' cx="105.35" cy="145.67" rx="38.5" ry="57"/>
      <polygon class="cls-1" fill= '#e0e0e0' points="308.35 41.16 370.35 41.16 286.35 163.16 328.35 163.16 328.35 128.16 383.35 128.16 383.35 163.16 414.35 163.16 414.35 209.16 383.35 209.16 383.35 250.16 327.35 250.16 327.35 208.16 215.35 208.16 215.35 173.16 308.35 41.16"/>
      <polygon class="cls-3" fill= '#27a4de' points="120.47 291.65 114.35 300.17 129.35 312.17 136.35 302.17 128.35 291.17 120.47 291.65"/>
      <polygon class="cls-3" fill= '#27a4de' points="147.35 303.17 148.69 303.99 153.35 313.17 170.35 304.17 165.35 296.17 155.35 296.17 147.35 303.17"/>
      <polygon class="cls-3" fill= '#27a4de' points="175.73 266.98 185.35 267.17 185.35 286.17 173.5 286.17 171.35 273.17 175.73 266.98"/>
      <polygon class="cls-3" fill= '#27a4de' points="153.56 243.27 160.35 235.16 175.35 246.16 169.35 254.16 157.35 249.16 153.56 243.27"/>
      <polygon class="cls-3" fill= '#27a4de' points="142.35 243.16 138.35 233.16 120.35 240.16 125.35 251.16 137.35 247.16 142.35 243.16"/>
      <polygon class="cls-3" fill= '#27a4de' points="104.35 260.17 104.35 280.17 115.14 280.17 119.35 270.17 117.2 260.17 104.35 260.17"/>
      <path class="cls-3" fill= '#27a4de' d="M124.35,134.17l13.58,13.57a2,2,0,0,1,.55,1c.63,3.35,2.58,18.92-15.34,19.37a1.91,1.91,0,0,1-1.35-.55l-15.44-15.44s-14,22,14,33c0,0,15,4,19-1l26,26,7-7-9-10,10-11-16-15s7-18-6-29A25.56,25.56,0,0,0,124.35,134.17Z"/>
      <polygon class="cls-3" fill= '#27a4de' points="201.35 178.16 200.35 178.16 197.35 170.16 213.35 163.16 217.35 173.16 210.35 178.16 201.35 178.16"/>
      <polygon class="cls-3" fill= '#27a4de' points="247.35 178.16 248.6 175.44 251.35 169.16 235.35 163.16 230.35 174.16 237.35 179.16 247.35 178.16"/>
      <polygon class="cls-3" fill= '#27a4de' points="176.52 201.92 168.35 194.16 180.35 181.16 186.73 187.54 182.35 197.16 176.52 201.92"/>
      <polygon class="cls-3" fill= '#27a4de' points="270.55 201.92 278.73 194.16 267.35 181.16 260.35 187.54 264.73 197.16 270.55 201.92"/>
      <polygon class="cls-3" fill= '#27a4de' points="172.25 214.94 162.35 215.16 162.35 233.16 172.03 233.16 174.35 220.16 172.25 214.94"/>
      <polygon class="cls-3" fill= '#27a4de' points="275.45 215.94 285.35 216.16 285.35 234.16 275.66 234.16 273.35 221.16 275.45 215.94"/>
      <polygon class="cls-3" fill= '#27a4de' points="177.35 247.16 170.35 254.16 181.35 266.17 186.35 261.41 183.35 251.16 177.35 247.16"/>
      <polygon class="cls-3" fill= '#27a4de' points="270.35 246.16 278.35 253.16 267.35 267.17 260.35 261.17 264.35 250.16 270.35 246.16"/>
      <polygon class="cls-3" fill= '#27a4de' points="231.35 276.17 235.35 285.17 252.41 278.17 248.7 270.95 237.41 272.17 231.35 276.17"/>
      <polygon class="cls-3" fill= '#27a4de' points="216.41 276.17 212.41 285.17 195.35 278.17 199.05 270.95 210.35 272.17 216.41 276.17"/>
      <path class="cls-3" fill= '#27a4de' d="M223.85,172.17a52.5,52.5,0,1,0,52.5,52.5A52.49,52.49,0,0,0,223.85,172.17Zm0,81a28.5,28.5,0,1,1,28.5-28.5A28.5,28.5,0,0,1,223.85,253.17Z"/>
      <path class="cls-3" fill= '#27a4de' d="M145.35,242.17a31,31,0,1,0,31,31A31,31,0,0,0,145.35,242.17Zm0,44a13,13,0,1,1,13-13A13,13,0,0,1,145.35,286.17Z"/>
    </svg>
    <svg version="1.1" id="service-leftarr-03" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-20 -18 50 50" fill= '#27a4de' >
        <path d="M26.485,21.206c0.429-0.444,0.429-1.143,0-1.587c-0.444-0.429-1.159-0.429-1.587,0l-8.047,8.047
        V1.111C16.851,0.492,16.359,0,15.74,0c-0.619,0-1.127,0.492-1.127,1.111v26.555l-8.031-8.047c-0.444-0.429-1.143-0.429-1.587,0
        c-0.429,0.444-0.429,1.143,0,1.587l9.952,9.952c0.429,0.429,1.143,0.429,1.587,0L26.485,21.206z"/>
    </svg>
  </div>

);

export default SVG;