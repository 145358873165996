import React from "react"

import {Button} from "react-bootstrap"
import ChillerIcon from "../../../Icons/EquipmentIcons/03-chillers.svg"

const ChillerButton = () => (

  <>
    <Button variant="eq-squared" size="xxl">
      <img src={ChillerIcon} className="equipment-btn-icon" alt="Оборудование агрегаты компрессорно испарительные" />
    </Button>
    <p style={{fontSize: "14px"}}>Агрегаты</p>
    <p style={{fontSize: "14px"}}>компрессорно-</p>
    <p style={{fontSize: "14px"}}>испарительные</p>
  </>
)

export default ChillerButton