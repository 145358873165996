import React from "react";
import ThermoSvg from "./Thermo"


const Thermo = () => (
<>
    <ThermoSvg />
</>
)

export default Thermo