import React from "react";
// import HeadSlider from "./HeadSlider"
import HeadSlider from "../../Modules/HeadSlider"


const HeadBlock = () => (
<>
    <HeadSlider />
</>
)

export default HeadBlock