import React from 'react';

import PlayButton from '../../Icons/ElementIcons/playbutton.svg';

import ModalVideo from "../../../Modules/VideoPresentation/ModalVideo"


export default function LayoutContact() {
  const [modalVideoShow, setModalVideoShow] = React.useState(false);
  
	return (
        <>
        <button className="video-learn-more" id="btnvid" onClick={() => setModalVideoShow(true)}>
          <span className="videocircle">
            <span className="videobuttontext">СМОТРЕТЬ ВИДЕО</span>
          </span>
           <span className="video-button-text">
            <img src = {PlayButton} alt=''/>
          </span>
        </button>

        <ModalVideo
          show={modalVideoShow}
          onHide={() => setModalVideoShow(false)}
        />

        </>
	);
  };