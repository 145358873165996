import React from "react";
import {Modal} from 'react-bootstrap'
import VideoBack from "./VideoBack"

import CloseButton from "../../Elements/SvgElements/OtherSvg/close.svg"


export default function ModalWindow (props) {

    
    return (
      <Modal
        {...props}
        aria-labelledby="custom-modal"
        centered
        dialogClassName="modal-90w"
      >
          
        <Modal.Body>
            <div role="button" tabindex="-1" aria-label="Close-modal-button" className="close-btn" onClick={props.onHide} onKeyDown={props.onHide} >
              <img src={CloseButton} alt="" />
            </div>
            
          <VideoBack {...props} />
          
        </Modal.Body>
      </Modal>
    );
  }

