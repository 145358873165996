import React from 'react';
import FooterCopyright from "./FooterCopyright"

const FooterBlock = () => {
    return(
        <>
            <FooterCopyright />        
        </>
    )
}

export default FooterBlock