import React from 'react';
import Video from './Video'

class VideoBack extends React.Component {
    render() {
    //   const opts = {
    //     height: '100vh',
    //     width: '100vw',
    //     playerVars: { // https://developers.google.com/youtube/player_parameters
    //       autoplay: 1,
    //       controls: 0,
    //       rel: 0,
    //       showinfo: 0,
    //       loop: 1,
          
          
    //     }
    //   };
  
      return (
        <div>
          <Video 
            videoSrcURL="https://www.youtube.com/embed/jSCd4GQSCRw"
            videoTitle='Презентация компании "Refengineering"'
          />
          </div>
      );
    }
  
    _onReady(event) {
      event.target.playVideo();
    }
  }

export default VideoBack;