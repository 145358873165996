import React from "react";
import {Fade} from 'react-reveal';
import {Link} from 'gatsby';
import Slider from "react-slick";


const PREFIX_URL = 'https://raw.githubusercontent.com/surreum/imagestore/main/refeng/projects/projectsimages';


export default function ProjectsBlock() {

    function NextArrow(props) {
        const { onClick } = props;
        return (

            <button  type='button' className = "project-animated-arrows-next" onClick={onClick}>
                <div className="project-animated-arrow project-animated-arrow-first"></div>
                <div className="project-animated-arrow project-animated-arrow-second"></div>
            </button>
        );
      }
      
    function PrevArrow(props) {
        const { onClick } = props;
        return (
            <button  type='button' className = "project-animated-arrows-prev" onClick={onClick}>
                <div className="project-animated-arrow project-animated-arrow-first"></div>
                <div className="project-animated-arrow project-animated-arrow-second"></div>
            </button>
        );
      }
   
    const settings = {
        dots: false,
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    }



    return (
        <div className="projectsblock-smphn">
            <h2 style={{display: 'grid', justifyContent: 'center'}}>НАМ ДОВЕРЯЮТ</h2>
            <Slider {...settings} className="projectslider-adaptive">
                <div className="projects-dscr">
                    <img src={PREFIX_URL + "/project-01.jpg"} style={{width: "100vw"}} alt="Посмотреть проект Агроркомбинат Московский" />
                    <Link to = "/realizovannye_proekty/agrokombinat-moskovskiy">
                    <Fade cascade duration={1000}>
                    <div className="projectslider-adaptive-description">
                        <h3>"Агрокомбинат Московский"</h3>
                        <h4>Производственно-складской комплекс</h4>
                        <h6>О проекте:</h6>
                        <p>Системы холодоснабжения 3МВт, отопление, вентиляция. 
                        Площадь охлаждаемых помещений 12 000 м². 
                        Температурные режимы от 0˚С до +12˚С, две камеры -18˚С.
                        </p>
                    </div>
                    </Fade>
                    </Link>
                </div>
                <div className="projects-dscr">
                    <img src={PREFIX_URL + "/project-02.jpg"} style={{width: "100vw"}} alt="Посмотреть проект BIMBO QSR" />
                    <Link to = "/realizovannye_proekty/bimbo">
                    <Fade cascade duration={1000}>
                    <div className="projectslider-adaptive-description">
                        <h3>"BIMBO QSR"</h3>
                        <h4>Производство хлебобулочных изделий 25тн/ч для McDonald's, Burger King, KFC и других крупных сетей</h4>
                        {/* <h6>Будет выполнено:</h6> */}
                        <p>Две транскритические системы
                        холодоснабжения 1,4МВт для технологии и роботизированного высотного самонесущего
                        фризера (50х17х29(h)м).
                        </p>
                    </div>
                    </Fade>
                    </Link>
                </div>
                <div className="projects-dscr">
                    <img src={PREFIX_URL + "/project-03.jpg"} style={{width: "100vw"}}  alt="Посмотреть проект Черкизово" />
                    <Link to = "/cherkizovo">
                    <Fade cascade duration={1000}>
                    <div className="projectslider-adaptive-description">
                        <h3>ГК "Черкизово"</h3>
                        <h4>Крупнейший в России производитель мясной продукции</h4>
                        {/* <h6>Несколько проектов:</h6> */}
                        <div>
                            <p style={{lineHeight: '1'}}>Аммиачная насосная система холодоснабжения ОАО "Биком"</p>
                            <p style={{lineHeight: '1'}}>Новая линия охлаждения тушек ледяной водой ООО "Лиско-Бройлер"</p>
                            <p style={{lineHeight: '1'}}>Камера шоковой заморозки АО "Петелинская птицефабрика"</p>
                            <p style={{lineHeight: '1'}}>Системы холодоснабжения экспедиции АО "Моссельпром"</p>
                        </div>
                    </div>
                    </Fade>
                    </Link>
                </div>
                <div className="projects-dscr">
                    <img src={PREFIX_URL + "/project-04.jpg"} style={{width: "100vw"}} alt="Посмотреть проект Озерецкий молочный комбинат" />
                    <Link to = "/ozeretzkiy-kombinat/">
                    <Fade cascade duration={1000}>
                    <div className="projectslider-adaptive-description">
                        <h3>ЗАО «Озерецкий молочный комбинат»</h3>
                        <h4>Крупный производитель молочной продукции в России</h4>
                        {/* <h6>Выполнено несколько проектов:</h6> */}
                        <p>Общая холодильная мощность новых и 
                        реконструированных систем более 5МВт.</p>
                    </div>
                    </Fade>
                    </Link>
                </div>
                <div className="projects-dscr">
                    <img src={PREFIX_URL + "/project-05.jpg"} style={{width: "100vw"}} alt="Посмотреть проект Сладкая Жизнь" />
                    <Link to = "/sladkaya-jizn">
                    <Fade cascade duration={1000}>
                    <div className="projectslider-adaptive-description">
                        <h3>ООО "Сладкая Жизнь"</h3>
                        <h4>Один из крупнейших поставщиков для сегмента HORECA</h4>
                        {/* <h6>Выполнено:</h6> */}
                        <div>
                            <p>Дистрибьюторский (Торгово-Распределительный) Центр 11'000м2. 
                                Системы холодоснабжения 1’700кВт. Система вентиляции и кондиционирования</p>
                            <p>Рыбное производство площадью 1400м<sup><small>2</small></sup></p>
                        </div>
                    </div>
                    </Fade>
                    </Link>
                </div>
                <div className="projects-dscr">
                    <img src={PREFIX_URL + "/project-06.jpg"} style={{width: "100vw"}} alt="Посмотреть проект Foodcode" />
                    <Link to = "/realizovannye_proekty/foodcode">
                    <Fade cascade duration={1000}>
                    <div className="projectslider-adaptive-description">
                        <h3>ГК "Сапсан"</h3>
                        <h4>Фабрика по производству кондитерских, кулинарных и хлебобулочных изделий.</h4>
                        <p style={{textAlign: 'left'}}>Производство замороженной безглютеновой продукции. 
                        Общая площадь производства 8’000м², готовая продукция 30т/сутки. Холодопроизводительность
                        систем 1,65МВт. Два контура ледяной воды.</p>
                    </div>
                    </Fade>
                    </Link>
                </div>
            </Slider>
            <div style={{display: 'grid', justifyContent: 'center'}}>
                <a href="/projects"><button className="ref-button-stoped">ВСЕ ПРОЕКТЫ</button></a>
            </div>
            
        </div>
    )
}
