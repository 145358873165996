import React from "react";

const SVG = () => (
  <div className="service-icon-top">
    <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.2 354.33">
      {/* <defs><style>.cls-1{fill:#e0e0e0;}.cls-2{fill:#fff;}.cls-3{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}.cls-4{fill:#27a4de;}</style></defs> */}
      <title>06</title>
      <path class="cls-1" fill= '#e0e0e0' d="M375.29,104.17l21.26-37.45S319.62,27.24,258.89,78.87a97,97,0,0,0-17.29,21.49,102.29,102.29,0,0,0-14.09,52.31v29.44c0,9.11,8.53,36.88,24.29,52.64C268,251,284.19,259,313.55,259s76.93-5.06,87.05-54.66-31.38-70.85-31.38-70.85-47.58-22.27-82,7.08c0,0-.65-26.53,20.24-38.46C328.73,90,345.94,92,375.29,104.17Z"/>
      <ellipse class="cls-1" fill= '#e0e0e0' cx="116.51" cy="156.04" rx="93.5" ry="103.5"/>
      <ellipse class="cls-2"  fill= '#fff' cx="117.51" cy="155.54" rx="38.5" ry="57"/>
      <circle class="cls-2"  fill= '#fff' cx="316.51" cy="192.04" r="26.5"/>
      <line class="cls-3" fill= '#27a4de' x1="128.51" y1="200.04" x2="117.51" y2="200.04"/>
      <line class="cls-3" fill= '#27a4de' x1="132.51" y1="162.04" x2="142.51" y2="171.04"/>
      <path class="cls-4" fill= '#27a4de' d="M264.51,190l-10.6,11.49a6.08,6.08,0,0,1-3.73,1.89c-13.75,1.73-15.57-8.13-15.72-12.43a4.4,4.4,0,0,1,1.17-3.15L247.51,175s-12-6-21,0c-18,11-8,31-8,31l-49,49c0,1,11,11,11,11a5.58,5.58,0,0,1,1,2l1,1,2,1,49-49c11,2,20,4,30-7S264.51,190,264.51,190Z"/>
      <path class="cls-4" fill= '#27a4de' d="M222.51,169c-3.86-7.72-12.28-14.61-18-18.81A50.24,50.24,0,0,0,186.92,142c-27.09-6.64-46.41,8-46.41,8s-25,15-27,49,30,52,30,52h21l48-49C204.51,181,222.51,169,222.51,169Zm-89.79-7.75,8.92,8-1.34,1.48-8.92-8ZM128.51,201h-12v-2h12ZM130,237l-1.41-1.41,8.48-8.49,1.42,1.42Zm42-29a7.49,7.49,0,0,1-7.35-6H130.51v-4h34.43a7.53,7.53,0,0,1,4.57-4.56V151h1v-9h3v9h1v42.44A7.49,7.49,0,0,1,172,208Zm28-41-1.41-1.41,8.48-8.49,1.42,1.42Z"/>
      <path class="cls-4" fill= '#27a4de' d="M162,253a24.5,24.5,0,1,0,24.5,24.5A24.5,24.5,0,0,0,162,253Zm.5,37.5A13.5,13.5,0,1,1,176,277,13.49,13.49,0,0,1,162.51,290.54Z"/>
    </svg>
  </div>

);

export default SVG;