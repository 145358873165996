import React from "react";

import Concept from './concept';
import Economy from './economy';
import Construct from './construct';
import Production from './production';
import Management from './management';
import Service from './service';

const Icon = props => {
  switch (props.name) {
    case "concept":
      return <Concept {...props} />;
    case "economy":
      return <Economy {...props} />;
    case "construct":
      return <Construct {...props} />;
    case "production":
      return <Production {...props} />;
    case "management":
      return <Management {...props} />;
    case "service":
      return <Service {...props} />;
    default:
      return;
  }
};

export default Icon;