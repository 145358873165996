import React from 'react'
import {useSwipeable } from "react-swipeable";

import ExpertizeNextButton from '../../Elements/Buttons/SliderButtons/NextButton'
import ExpertizePrevButton from '../../Elements/Buttons/SliderButtons/PrevButton'

import FrozenCool from './expertizeImg/ohl_n_zamoroz-min.jpg';
import CoolWarhs from './expertizeImg/warehouse-cooling-min.jpg';
import ClimRooms from './expertizeImg/climat-camera-min.jpg';
import HvacAir from './expertizeImg/hvac-min.jpg';
import TechLines from './expertizeImg/tech-line-min.jpg';
import IceWater from './expertizeImg/led-min.jpg';
import IceGen from './expertizeImg/ice-gen-min.jpg';
import Recuperation from './expertizeImg/recuperation-min.jpg';


const slides = [
    {
      id: 1,
      title: "Охлаждение и заморозка",
      link: "/competency/shokovaya-zamorozka/" ,
      image: FrozenCool
    },
    {
      id: 2,
      title: `Охлаждаемые склады`,
      link: "/competency/ohlagdaemye-sklady/",
      image: CoolWarhs
    },
    {
      id: 3,
      title: "Климатические камеры",
      link: "/competency/climatic-chambers",  
      image: ClimRooms
    },
    {
      id: 4,
      title: "Вентиляция и кондиционирование производственных помещений",
      link: "/konditzionirovanie-proizvodstv/",
      image: HvacAir
    },
    {
      id: 5,
      title: "Охлаждение технологических линий",
      link: "/competency/liquid-cooling",
      image: TechLines
    },
    {
      id: 6,
      title: "Ледяная вода",
      link: "/competency/ice-water",
      image: IceWater
    },
    {
      id: 7,
      title: "Льдогенераторы, хранение и транспортировка льда",
      link: "/competency/icegenerators",
      image: IceGen
    },
    {
      id: 8,
      title: "Рекуперация тепла для технологических нужд и отопления",
      link: "/competency/heatrecovery",
      image: Recuperation
    },
  ];

const ExpertizeCarousel = () => {
    const [selectedIdx, setSelectedIdx] = React.useState(0);
    const [slideOrder, setSlideOrder] = React.useState(['s7', 's8', 's1', 's2', 's3', 's4', 's5','s6' ]);
    const [slideStyles, setSlideStyles] = React.useState({});
  
    const rotate = (s1, s2, s3, s4, s5, s6, s7, s8) => {
      setSlideStyles({
        [s8]: { transform: "translateX(-440%)", opacity: 0 },
        [s1]: { transform: "translateX(-330%)", opacity: 0 },
        [s2]: { transform: "translateX(-220%)", opacity: 0 },
        [s3]: { transform: "translateX(-110%)", opacity: 1 },
        [s4]: { transform: "translateX(0)", opacity: 1 },
        [s5]: { transform: "translateX(110%)", opacity: 1 },
        [s6]: { transform: "translateX(220%)", opacity: 0 },
        [s7]: { transform: "translateX(330%)", opacity: 0 },
      });
      setSlideOrder([s1, s2, s3, s4, s5, s6, s7, s8]);
    };

    // Swipe 
    const handlers = useSwipeable({
      onSwipedLeft: () => rotateLeft(),
      onSwipedRight: () => rotateRight(),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true
    });
  
    // rotate slides right by n spaces: e.g. 2 spaces - [1, 2, 3, 4, 5] -> [4, 5, 1, 2, 3]
    const rotateRight = (spaces = 1) => {
      const s = [];
  
      slideOrder.forEach((slide, i) => {
        s[(i + spaces) % slideOrder.length] = slide;
      });
  
      setSelectedIdx(7 - (7 - selectedIdx + spaces) % 8);
      rotate(s[0], s[1], s[2], s[3], s[4], s[5], s[6], s[7]);
    };
  
    // rotate slides left by n spaces: e.g. 2 spaces - [1, 2, 3, 4, 5] -> [3, 4, 5, 1, 2]
    const rotateLeft = (spaces = 1) => {
      const s = [];
  
      slideOrder.forEach((_, i) => {
        s[i] = slideOrder[(i + spaces) % 8];
      });
  
      setSelectedIdx((selectedIdx + spaces) % 8);
      rotate(s[0], s[1], s[2], s[3], s[4], s[5], s[6], s[7]);
    };
  
    const handleDotClick = idx => {
      if (idx > selectedIdx) {
        rotateLeft(idx - selectedIdx);
      } else if (idx < selectedIdx) {
        rotateRight(selectedIdx - idx);
      }
    };
  


    return (
      
      <div className="carousel-wrap" {...handlers}>
        <div className="carousel-container">
          <div role = "button" tabIndex= "-1" className="carousel-btn prev-btn" onClick={() => rotateRight()} onKeyDown={() => rotateRight()} >
            <ExpertizePrevButton  />
          </div>
          <div className="carousel-slide-list" >
            {slides.map((slide, i) => (
              <CarouselSlideItem
                key={slide.id}
                slide={slide}
                style={slideStyles[`s${slide.id}`]}
                active={selectedIdx === i}
                className={`carousel-slide-item s${slide.id}`}
                
              />
            ))}
          </div>
          <div role="button" tabIndex = "-1" className="carousel-btn next-btn" onClick={() => rotateLeft()} onKeyDown={() => rotateLeft()} >
            <ExpertizeNextButton  />
          </div>
           

          {/* <button className="carousel-btn next-btn" onClick={() => rotateLeft()}>
            <i className="carousel-btn__arrow right" />
          </button> */}
        </div>
        <div className="carousel-dots">
          {slides.map((_, i) => {
            const className = selectedIdx === i ? "dot active" : "dot";
            return (
              <button
                aria-label = "dots"
                key={i}
                className={className}
                onClick={() => handleDotClick(i)}
              />
            );
          })}
        </div>
      </div>
    );
  };


  
  const CarouselSlideItem = ({ slide, style, className, active }) => (

    
    <div className={className} style={style}>
      <a className="carousel-slide-item__img-link" href={slide.link}>
        <img
          src={slide.image}
          className={active ? "active" : ""}
          alt={slide.id}
        />
         
      </a>
      <div className="carousel-slide-item__body">
        <h4>{slide.title}</h4>
        {/* <div className="expertize-link">{slide.link}</div> */}
      </div>
    </div>
  );
  
  
  
 export default ExpertizeCarousel;
  
  