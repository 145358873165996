import React from "react"

import {Button} from "react-bootstrap"
import VolumesIcon from "../../../Icons/EquipmentIcons/09-volumes.svg"

const VolumesButton = () => (

  <>
    <Button variant="eq-squared" size="xxl">
      <img src={VolumesIcon} className="equipment-btn-icon" alt="Оборудование агрегаты компрессорно испарительные" />
    </Button>
    <p style={{fontSize: "14px"}}>Емкости</p>
    <p style={{fontSize: "14px"}}>буферные</p>
    <p style={{fontSize: "14px"}}></p>
  </>
)

export default VolumesButton