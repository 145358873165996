import React from 'react';
import Articles from "./Articles";
import OrderForm from "../../Modules/ContactForms/OrderForm"
import Footer from "../../Modules/Footer";


const ArticlesBlock = () => {
    return(
        <>
            <div className="mainpage-block-container partners-container">
                <h2 style={{textAlign: 'left', paddingLeft: "4vw", top: "0"}}>публикации</h2>
                <Articles />  
                <OrderForm />
            </div>
            <Footer />
        </>     
    )
}

export default ArticlesBlock