import React from 'react';
import {Fade} from 'react-reveal';
import {Flip} from 'react-reveal';
import {Link} from 'gatsby';

import PropTypes from 'prop-types';

import {Container, Row, Col} from "react-bootstrap";

//Import images
import SlideMoskovskiy from './Projectslides/moskovskiy-min.jpg';
import SlideBimbo from './Projectslides/bimbo-min.jpg';
import SlideCherkizovo from './Projectslides/cherkizovo-min.jpg';
import SlideOzeretzkiy from './Projectslides/ecomilk-min.jpg';
import SlideSweetlife from './Projectslides/sweetlife2-min.jpg';
import SlideSapsan from './Projectslides/sapsan-min.jpg';

// Images Adaptive
import SlideMoskovskiyAdaptive from './ProjectsSlidesAdaptive/moskovskiy-min-adaptive.jpg';
import SlideBimboAdaptive from './ProjectsSlidesAdaptive/bimbo-min-adaptive.jpg';
import SlideCherkizovoAdaptive from './ProjectsSlidesAdaptive/cherkizovo-min-adaptive.jpg';
import SlideOzeretzkiyAdaptive from './ProjectsSlidesAdaptive/ecomilk-min-adaptive.jpg';
import SlideSweetlifeAdaptive from './ProjectsSlidesAdaptive/sweetlife2-min-adaptive.jpg';
import SlideSapsanAdaptive from './ProjectsSlidesAdaptive/sapsan-min-adaptive.jpg';


import MosagroLogo from "../../../images/mainpage/svg/mosagro-logo.svg"
import BimboLogo from "../../../images/mainpage/svg/bimbo-logo.svg"
import CherkizLogo from "../../../images/mainpage/svg/cherkizovo-logo.svg"
import EcomilkLogo from "../../../images/mainpage/svg/ecomilk-logo.svg"
import SjLogo from "../../../images/mainpage/svg/sladkaya-jizn-logo.svg"
import SapsanLogo from "../../../images/mainpage/svg/sapsan-logo.svg"



class ProjectSlider extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            slides: [
              {
                eachProject: "url(" + SlideMoskovskiy + ")",
                eachProjectAdaptive: "url(" + SlideMoskovskiyAdaptive + ")",
                eachProjectDescription: 
                <Link to = "/realizovannye_proekty/agrokombinat-moskovskiy">
                <Fade cascade duration={1000}>
                <div className='description-text'>
                        <h3>"Агрокомбинат Московский"</h3>
                        <h4>Производственно-складской комплекс</h4>
                        <h6>О проекте:</h6>
                            <p>Системы холодоснабжения 3МВт, отопление, вентиляция. 
                            Площадь охлаждаемых помещений 12 000 м². 
                            Температурные режимы от 0˚С до +12˚С, две камеры -18˚С.
                            </p>
                </div>
                </Fade>
                </Link>,
                eachLogo: MosagroLogo,
                eachProjectNumber: '01',
                nextProjectNumber: '02'
              },
              {
                eachProject: "url(" + SlideBimbo + ")",
                eachProjectAdaptive: "url(" + SlideBimboAdaptive + ")",
                eachProjectDescription: 
                <Link to = "/realizovannye_proekty/bimbo">
                <Fade cascade duration={1000}>
                <div className='description-text'>
                     <h3>"BIMBO QSR"</h3>
                    <h4>Производство хлебобулочных изделий 25тн/ч для McDonald's, Burger King, KFC и других крупных сетей</h4>
                    {/* <h6>Будет выполнено:</h6> */}
                        <p>Две транскритические системы
                        холодоснабжения 1,4МВт для технологии и роботизированного высотного самонесущего
                        фризера (50х17х29(h)м).
                        </p>
                </div>
                </Fade>
                </Link>,
                eachLogo: BimboLogo,
                eachProjectNumber: '02',
                nextProjectNumber: '03'
              },
              {
                eachProject: "url(" + SlideCherkizovo + ")",
                eachProjectAdaptive: "url(" + SlideCherkizovoAdaptive + ")",
                eachProjectDescription: 
                <Link to = "/cherkizovo">
                <Fade cascade duration={1000}>
                <div className='description-text'>
                    <h3>ГК "Черкизово"</h3>
                    <h4>Крупнейший в России производитель мясной продукции</h4>
                    {/* <h6>Несколько проектов:</h6> */}
                        <div>
                            <p style={{lineHeight: '1'}}>Аммиачная насосная система холодоснабжения ОАО "Биком"</p>
                            <p style={{lineHeight: '1'}}>Новая линия охлаждения тушек ледяной водой ООО "Лиско-Бройлер"</p>
                            <p style={{lineHeight: '1'}}>Камера шоковой заморозки АО "Петелинская птицефабрика"</p>
                            <p style={{lineHeight: '1'}}>Системы холодоснабжения экспедиции АО "Моссельпром"</p>
                        </div>
                </div>
                </Fade>
                </Link>,
                eachLogo: CherkizLogo,
                eachProjectNumber: '03',
                nextProjectNumber: '04'
              },
              {
                eachProject: "url(" + SlideOzeretzkiy + ")",
                eachProjectAdaptive: "url(" + SlideOzeretzkiyAdaptive + ")",
                eachProjectDescription: 
                <Link to = "/ozeretzkiy-kombinat/">
                <Fade cascade duration={1000}>
                <div className='description-text'>
                    <h3>ЗАО «Озерецкий молочный комбинат»</h3>
                    <h4>Крупный производитель молочной продукции в России</h4>
                    {/* <h6>Выполнено несколько проектов:</h6> */}
                    <p>Общая холодильная мощность новых и 
                    реконструированных систем более 5МВт.</p>
                </div>
                </Fade>
                </Link>,
                eachLogo: EcomilkLogo,
                eachProjectNumber: '04',
                nextProjectNumber: '05'
              },
              {
                eachProject: "url(" + SlideSweetlife + ")",
                eachProjectAdaptive: "url(" + SlideSweetlifeAdaptive + ")",
                eachProjectDescription:
                <Link to = "/sladkaya-jizn">
                <Fade cascade duration={1000}>
                <div className='description-text'>
                    <h3>ООО "Сладкая Жизнь"</h3>
                    <h4>Один из крупнейших поставщиков для сегмента HORECA</h4>
                    {/* <h6>Выполнено:</h6> */}
                    <div>
                        <p>Дистрибьюторский (Торгово-Распределительный) Центр 11'000м2. 
                            Системы холодоснабжения 1’700кВт. Система вентиляции и кондиционирования</p>
                        <p>Рыбное производство площадью 1400м<sup><small>2</small></sup></p>
                    </div>
                </div>
                </Fade>
                </Link>,
                eachLogo: SjLogo,
                eachProjectNumber: '05',
                nextProjectNumber: '06'
              },
              {
                eachProject: "url(" + SlideSapsan + ")",
                eachProjectAdaptive: "url(" + SlideSapsanAdaptive + ")",
                eachProjectDescription: 
                <Link to = "/realizovannye_proekty/foodcode">
                    <Fade cascade duration={1000}>
                        <div className='description-text'>
                            <h3>ГК "Сапсан"</h3>
                                <h4>Фабрика по производству кондитерских, кулинарных и хлебобулочных изделий.</h4>
                                    <p style={{textAlign: 'left'}}>Производство замороженной безглютеновой продукции. 
                                    Общая площадь производства 8’000м², готовая продукция 30т/сутки. Холодопроизводительность
                                    систем 1,65МВт. Два контура ледяной воды.</p>
                        </div>
                    </Fade>
                </Link>,
                eachLogo: SapsanLogo,
                eachProjectNumber: '06',
                nextProjectNumber: ''
              }
            ],
            autoplay: false,
            active: 0,
            max: 0,
        };

        this.state.max = this.state.slides.length;
        this.intervalBetweenSlides = this.intervalBetweenSlides.bind(this);
    }

    componentDidMount() {

        this.interval = setInterval( () => this.intervalBetweenSlides(), 500); 
    }

    componentWillUnmount() {

        clearInterval( this.interval );
    }

    intervalBetweenSlides() {
        
        if (this.state.active === this.state.max-1) {

                this.setState.active = 0;

            } else {

                this.setState.active++;
            }
            
            this.setState({
                active: this.state.active
            });
    }

    setSliderStyles(){

        const transition = this.state.active * - 72.72;
            
            
        return {
            width: ( this.state.slides.length * 100 ) + 'vw',
            transform: 'translateX(' + transition + 'vw)'
        }
    }

    setSliderStylesAdaptive(){
        const transition = this.state.active * - 105;
        return {
            width: ( this.state.slides.length * 110 ) + 'vw',
            transform: 'translateX(' + transition + 'vw)'
        }
    }

    setSliderStylesAdaptiveSm(){
        const transition = this.state.active * - 106.5;
        return {
            width: ( this.state.slides.length * 110 ) + 'vw',
            transform: 'translateX(' + transition + 'vw)'
        }
    }

    setSliderStylesAdaptiveXS(){
        const transition = this.state.active * - 115.72;
        return {
            width: ( this.state.slides.length * 116 ) + 'vw',
            transform: 'translateX(' + transition + 'vw)'
        }
    }

    nextOne(){
        
        if (this.state.active < this.state.max - 1) {

            this.setState({
                active: this.state.active + 1
            });
        }
    }

    prevOne(){
        
        if (this.state.active > 0) {
            
            this.setState({
                active: this.state.active - 1
            });
        }
    }

    dots(index, event){
        
        this.setState({
            active: index
        });
    }

    isActive(value){
        if(this.state.active === value){
            return 'active';
        }
        if(this.state.active !== value){
            return 'inactive';
        }
    }

    renderSlides() {

        return this.state.slides.map( (item, index) => (

            <>
                <div 
                    className='each-projectslide' 
                    key = {index} 
                    style={{ backgroundImage: item.eachProject }}>
                    
                </div> 
            </>
            )
        );
    }

    isActiveDescription(value){
        if(this.state.active === value){
            return 'activedescription';
            
        } else {
            return 'nonactivedescription';
        }
    }

    renderStyledButton(){
        if(this.state.active !== 6){
            return <div>
                <Fade duration={3000}>
                    <Link to = '/projects' style={{textDecoration: 'none'}}>
                        <div className="allprojectsbuttonbig">
                            <button id="connect-button" className="ref-button projects-button">
                                ВСЕ ПРОЕКТЫ
                            </button>
                        </div>
                    </Link>
                </Fade>
                        </div>
            
        } else {
            return <div>
                <Link to = '/projects' style={{textDecoration: 'none'}} >
                    <Flip left cascade>
                        <button id="connect-button" className="connect-button-styled">
                            ВСЕ ПРОЕКТЫ
                        </button>
                    </Flip>
                </Link>
                <Link to = '/projects' style={{textDecoration: 'none'}} >
                </Link>
                    </div>
        }
    }



    renderSlidesDescriptions() {

        return this.state.slides.map( (item, index) => (

                <div 
                    className={this.isActiveDescription(index)}>
                    { item.eachProjectDescription }
                </div> 
            )
        );
    }


    isActiveNumber(value){
        if(this.state.active === value){
            return 'activenumber';
            
        } else {
            return 'nonactivenumber';
        }
    }

    isActiveNextNumber(value){
        if(this.state.active === value){
            return 'activenextnumber';
            
        } else {
            return 'nonactivenextnumber';
        }
    }

    renderSlidesNumber() {

        return this.state.slides.map( (item, index) => (

                <div
                    className={this.isActiveNumber(index)}
                >
                    { item.eachProjectNumber }
                </div> 
            )
        );
    }

    renderNextNumber() {

        return this.state.slides.map( (item, index) => (

                <div
                    className={this.isActiveNextNumber(index)}
                >
                    { item.nextProjectNumber }
                </div> 
            )
        );
    }

    

    renderDots() {

        return this.state.slides.map( (item, index) => (

            <div
                role = "button"
                tabIndex = "-1"
                key = {index}  
                onClick={ this.dots.bind(this, index)} 
                onKeyDown = { this.dots.bind(this, index) } 
            >
                <img 
                    className={this.isActive(index) + ' dots' } 
                    src = {item.eachLogo} 
                    alt=""
                    aria-label = "dot-button"
                />
            </div>


            )
        );
    }


    renderArrows() {
        if(this.state.active === 0){
            return (
            <div>
                <button  type='button' className = "project-animated-arrows-next" onClick={this.nextOne.bind(this)}>
                    <div className="project-animated-arrow project-animated-arrow-first"></div>
                    <div className="project-animated-arrow project-animated-arrow-second"></div>
				</button>
                {/* { this.renderSlidesNumber() } */}
                {/* { this.renderNextNumber() } */}
                </div>
                
            )
        } if(this.state.active === 5){
            return (
            <div>
                 <button  type='button' className = "project-animated-arrows-prev" onClick={this.prevOne.bind(this)}>
                    <div className="project-animated-arrow project-animated-arrow-first"></div>
                    <div className="project-animated-arrow project-animated-arrow-second"></div>
                </button>
                {/* { this.renderSlidesNumber() } */}
                {/* { this.renderNextNumber() } */}
                </div>
                
            )
        }else {
        return (
            
            <div>
                <button  type='button' className = "project-animated-arrows-prev" onClick={this.prevOne.bind(this)}>
                    <div className="project-animated-arrow project-animated-arrow-first"></div>
                    <div className="project-animated-arrow project-animated-arrow-second"></div>
                </button>
                {/* { this.renderSlidesNumber() } */}
                <button  type='button' className = "project-animated-arrows-next" onClick={this.nextOne.bind(this)}>
                    <div className="project-animated-arrow project-animated-arrow-first"></div>
                    <div className="project-animated-arrow project-animated-arrow-second"></div>
                </button>
            </div>
        );
        }
    }



    // div className="ref-projects-container"


    render(){
        
        return (
            <>
            <div className="projectsblock-desktop">
            <Container fluid>
                <Row style={{alignItems: "center"}}>
                    <Col xl={5} lg={5} md={12} sm={12}>
                        <div className="ref-projects-title">
                            <h1 style={{display: 'inline-flex', verticalAlign: 'middle'}}>НАМ ДОВЕРЯЮТ</h1>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                        <div className="ref-projects-brand-container">
                            { this.renderDots() }
                        </div>                
                    </Col>
                </Row>

                <Row className="row-slider">
                    <Col className='projectslider'>
                        <div className='projectwrapper' style={ this.setSliderStyles() }>
                            { this.renderSlides() }
                        </div>
                        <div className = "arrowswrapper">
                            { this.renderArrows() } 
                        </div>
                    </Col>
                </Row>

                <Row className="row-slider-adaptive">
                    <Col className='projectslider'>
                        <div className='projectwrapper' style={ this.setSliderStylesAdaptive() }>
                            { this.renderSlides() }
                        </div>
                        <div className = "arrowswrapper">
                            { this.renderArrows() } 
                        </div>
                    </Col>
                </Row>

                <Row className="row-slider-adaptive-sm">
                    <Col className='projectslider'>
                        <div className='projectwrapper' style={ this.setSliderStylesAdaptiveSm() }>
                            { this.renderSlides() }
                        </div>
                        <div className = "arrowswrapper">
                            { this.renderArrows() } 
                        </div>
                    </Col>
                </Row>

                <Row className="row-slider-adaptive-xs">
                    <Col className='projectslider'>
                        <div className='projectwrapper' style={ this.setSliderStylesAdaptiveXS() }>
                            { this.renderSlides() }
                        </div>
                        <div className = "arrowswrapper">
                            { this.renderArrows() } 
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xl={5} lg={6} md={12} sm={12} className="description-block">
                        { this.renderSlidesDescriptions() }
                        { this.renderSlidesNumber() }
                    </Col>
                    <Col xl={2} lg={4} md={12} sm={12} className="button-projects">                
                        <div className="ref-projects-button">
                            { this.renderStyledButton() }
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
            </>
        );
    }
};


ProjectSlider.propTypes = {
    renderDots: PropTypes.func.isRequired,
    dots: PropTypes.func.isRequired,
  };

export default ProjectSlider;

