import React from "react";
import TopDarkMode from './TopDarkMode';


const TopDark = () => (
<>
        <TopDarkMode />
</>
)

export default TopDark