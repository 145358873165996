import React from 'react';
import {Container, Row, Col} from "react-bootstrap"

import ExpertSlider from "../../Modules/ExpertSlider/expertizecarousel"


export default function ExpertizeMod() {

    return(
        <>
            <Container fluid className="mainpage-block-container">
                <Row className="content-expertize-row">
                    <Col xl={4} lg={4} md={4} sm={12} style={{alignSelf: "center"}}>
                        <h2 style={{color: '#00a0e3', textAlign: "left", paddingLeft: "3vw", display: 'grid', alignContent: 'center'}}>направления с которыми мы работаем</h2>
                    </Col>
                    <Col xl={7} lg={7} md={7} sm={12}>
                        <ExpertSlider />
                    </Col>
                </Row>
                <Row className="content-expertize-row-adaptive">
                    <Col md={12} sm={12}>
                        <h2 style={{color: '#00a0e3', textAlign: "center", display: 'grid', alignContent: 'center'}}>направления с которыми мы работаем</h2>
                    </Col>
                    <Col md={12} sm={12}>
                        <ExpertSlider />
                    </Col>
                </Row>
            </Container>
            
        </>
    )
}