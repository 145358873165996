import React from "react"

import {Button} from "react-bootstrap"
import ClimatIcon from "../../../Icons/EquipmentIcons/07-climatblocks.svg"

const ClimatButton = () => (

  <>
    <Button variant="eq-squared" size="xxl">
      <img src={ClimatIcon} className="equipment-btn-icon" alt="Оборудование агрегаты компрессорно испарительные" />
    </Button>
    <p style={{fontSize: "14px"}}>Климатические</p>
    <p style={{fontSize: "14px"}}>блоки</p>
    <p style={{fontSize: "14px"}}></p>
  </>
)

export default ClimatButton