import React, {Component} from "react"
import Preloader from "../components/Modules/Preloader"
import Seo from "../components/seo"
import MainPage from "../components/MainPage"

const schema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Рефинжиниринг",
  alternateName: "Refengineering",
  description: "Холодоснабжение промышленных предприятий. Аммиачные холодильные установки. Транскритические системы CO2.",
  url: "https://refeng.ru/",
  logo: "https://raw.githubusercontent.com/surreum/imagestore/main/refeng/main/logo-refengineering.jpg",
  telephone: "+74957210111",
  address: {
    "@type": "PostalAddress",
    streetAddress: "Вашутинское шоссе, 24",
    addressLocality: "Химки",
    postalCode: "141400",
    addressCountry: "RU",
  },
  geo: {
    "@type": "GeoCoordinates",
    latitude: 55.921268, 
    longitude: 37.414030,
  },
  openingHoursSpecification: {
    "@type": "OpeningHoursSpecification",
    dayOfWeek: ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница"],
    opens: "10:00",
    closes: "20:00",
  },
  sameAs: [
    "https://www.facebook.com/refengineering/",
    "https://www.instagram.com/refengineering/",
    "https://www.youtube.com/channel/UCEa9t_Pn4q5Iz17rvd1tEcA",
    "https://refeng.ru/"
  ]
}


class Index extends Component {
  state = {
    content: <Preloader text="Загрузка..." />
  };

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          content: (
            <>
              <Seo 
                schemaMarkup={schema}
                title="Промышленные системы холодоснабжения. Холодильные агрегаты."
                description="Экспертное холодоснабжение от Рефинжиниринг.🌡 Производство 🛠 и обслуживание холодильных систем 👨🏻‍🔧, компрессорных холодильных агрегатов, льдоаккумуляторов 🧊 и прочего холодильного оборудования. ❄️"
              />
              <MainPage />
            </>
          )
        }),
      2000
    );
  }
  render() {
    return this.state.content;
  }
}

export default Index;


