import React from "react"


const ScrollDownArrows = () => (

    <div className = "scrldwnarrows">   
        <div className="scrldwnarrow scrldwnarrow-first"></div>
        <div className="scrldwnarrow scrldwnarrow-second"></div>
    </div>

)

export default ScrollDownArrows