import React, {useState} from 'react';
import {Link} from 'gatsby'
import {Fade} from "react-reveal"

import Icon from "./Icons"



import {Container, Row, Col} from 'react-bootstrap'


function Services() {
    const [servicetext, setText] = useState(<Text1 />)


    return(
        <Container fluid className="service-block-container">
            <Row>
                <Col xl={3} lg={3} md={3} sm={3} xs={3} className="service-icons-grid">
                    <div onMouseEnter = {() => {setText(<Text1 />)}} role="button" tabIndex={0} style={{display: 'grid'}}>
                        <Link to = "/kontzeptziya-proekta">
                            <Icon name="concept" />
                            <div className='arrow-service-block'>
                                <svg version="1.1" id="service-dwnarr" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-8 -10 50 50" fill= '#27a4de' >
                                <path d="M26.485,21.206c0.429-0.444,0.429-1.143,0-1.587c-0.444-0.429-1.159-0.429-1.587,0l-8.047,8.047
                                V1.111C16.851,0.492,16.359,0,15.74,0c-0.619,0-1.127,0.492-1.127,1.111v26.555l-8.031-8.047c-0.444-0.429-1.143-0.429-1.587,0
                                c-0.429,0.444-0.429,1.143,0,1.587l9.952,9.952c0.429,0.429,1.143,0.429,1.587,0L26.485,21.206z"/>
                                </svg>
                            </div>
                      </Link>
                    </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={12} className="service-text">
                    {servicetext}
                </Col>
                <Col xl={3} lg={3} md={3} sm={3} xs={3} className="service-icons-grid">
                    <div onMouseEnter = {() => {setText(<Text6 />)}} role="button" tabIndex={0} style={{display: 'grid'}}>
                        <Link to = "/service-guarantee">
                            <Icon name="service" />
                            <div className='arrow-service-block'>
                                <svg version="1.1" id="service-uparr" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-8 -10 50 50" fill= '#27a4de' >
                                    <path d="M26.485,21.206c0.429-0.444,0.429-1.143,0-1.587c-0.444-0.429-1.159-0.429-1.587,0l-8.047,8.047
                                    V1.111C16.851,0.492,16.359,0,15.74,0c-0.619,0-1.127,0.492-1.127,1.111v26.555l-8.031-8.047c-0.444-0.429-1.143-0.429-1.587,0
                                    c-0.429,0.444-0.429,1.143,0,1.587l9.952,9.952c0.429,0.429,1.143,0.429,1.587,0L26.485,21.206z"/>
                                </svg>
                            </div>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="service-second-row">
                <Col xl={3} lg={3} md={3} sm={3} xs={3} className="service-icons-grid">
                    <div onMouseEnter = {() => {setText(<Text2 />)}} role="button" tabIndex={0} style={{display: 'grid'}}>
                        <Link to = "/teh-obosnovanie">
                            <Icon name="economy" />
                        </Link>
                    </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={3} xs={3} className="service-icons-grid">
                    <div onMouseEnter = {() => {setText(<Text3 />)}} role="button" tabIndex={0} style={{display: 'grid'}}>
                        <Link to = "/oformlenie-dokumentatzii">
                            <Icon name="construct" />
                        </Link>
                    </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={3} xs={3} className="service-icons-grid">
                    <div onMouseEnter = {() => {setText(<Text4 />)}} role="button" tabIndex={0} style={{display: 'grid'}}>
                        <Link to = "/proizvodstvo-oborudovaniya">
                            <Icon name="production" />
                        </Link>
                    </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={3} xs={3} className="service-icons-grid">
                <div onMouseEnter = {() => {setText(<Text5 />)}} role="button" tabIndex={0} style={{display: 'grid'}}>
                    <Link to = "/upravlenie-proektom">
                        <Icon name="management" />
                    </Link>
                </div>
                </Col>
            </Row>
        </Container>
    )
}


const Text1 = () => {
    return(
        <Fade duration = {2000}>
            <div>
                <h3>От идеи до готовой концепции</h3>
                <p>Воплотим идеи заказчика в концепцию, оптимизируем работу существующего предприятия. Наш опыт и знания — это простые, надежные и эффективные инструменты, которые гарантируют результат.</p>
            </div>
        </Fade>

    )
}

const Text2 = () => {
    return(
        <Fade duration = {2000}>
            <div>
                <h3>Предварительный экспертный расчет</h3>
                <p>Проведем комплексную оценку капитальных и эксплуатационных затрат. Определим оптимальные технические решения и сроки окупаемости.</p>
            </div>
        </Fade>
    )
}

const Text3 = () => {
    return(
        <Fade duration = {2000}>
            <div className='showtext3'>
              <h3>Разработка полного комплекта документации</h3>
                <p>Выполняем проектирование как отдельных разделов, так и полного комплекта, включающего в себя:</p>
                <ul>
                    <li>Предпроектная документация (стадия «Буклет»);</li>
                    <li>Проектная документация в соответствии с Постановлением №87 от 21.12.2020г.;</li>
                    <li>Рабочая документация в соответствии с ГОСТ Р 21.101-2020г. и ГОСТ 2.102-2013г.;</li>
                    <li>Проектирование опасных производственных объектов (ОПО);</li>
                    <li>Проведение экспертизы промышленной безопасности;</li>
                    <li>Подготовка документации для постановки на учёт в Ростехнадзор.</li>
                </ul>
            </div> 
        </Fade>
    )
}

const Text4 = () => {
    return(
        <Fade duration = {2000}>
            <div>
                <h3>Свое производство</h3>
                <p>Конструирование и полный производственный цикл промышленного холодильного оборудования. Любые нестандартные задачи реализуются с высоким качеством не уступающим европейским аналогам.</p>
              </div> 
        </Fade>
    )
}

const Text5 = () => {
    return(
        <Fade duration = {2000}>
            <div>
                <h3>Комплексное планирование</h3>
                <p>Управление проектом является одним из ключевых моментов реализации проекта. Мы основательно подходим к его управлению и используем современные EPCm инструменты.</p>
            </div> 
        </Fade>
    )
}

const Text6 = () => {
    return(
        <Fade duration = {2000}>
            <div>
                <h3>Круглосуточная сервисная служба</h3>
                <p>Выполняем пусконаладочные работы, гарантийные обязательства и техническое обслуживание 24/7/365. Работаем с промышленными холодильными системами любого уровня сложности и автоматизации.</p>
            </div> 
        </Fade>
    )
}

export default Services