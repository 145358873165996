import React from "react";

const ThermoSvg = ({
	id= "thermo",
	width = '',
    fillcls1 = '#ffffff',
    fillcls2 = '#00a0e3',
    fontSize3 = '49.41px',
    fontFamily = 'ArialMT, Arial',
    letterSpacing = '-0.07em',
    fontSize6 = '57.73px', 
    fillcls6 = '#ffffff',
    viewBox ="0 0 350 1695.08"
}) =>(
	<div>
<svg
id = {id}
width = {width}
viewBox={viewBox}
xmlns="http://www.w3.org/2000/svg" >
    {/* <title>Termometr</title> */}
	<g id="Red_scale" data-name="Red scale">
		<rect fill={fillcls1} x="100.34" y="100.4" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="88.52" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="161.67" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="223.07" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="283.47" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="173.8" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="149.54" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="136.53" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="124.97" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="112.69" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="185.8" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="198.43" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="210.75" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="234.89" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="246.71" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="259.71" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="271.59" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="344.02" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="404.24" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="458.16" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="514.73" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="356.15" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="331.89" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="318.89" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="307.32" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="295.04" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="368.16" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="380.79" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="393.11" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="414.74" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="426" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="436.31" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="447.65" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="468.91" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="480.04" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="492.05" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="503.22" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="526.48" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="574.72" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="637.81" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="705.94" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="779.87" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="860.09" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="538.05" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="549.87" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="562.13" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="586.39" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="599.31" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="611.73" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="625.36" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="651.81" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="664.76" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="678.2" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="691.68" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="749.54" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="720.33" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="734.85" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="764.3" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="810.51" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="795.08" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="826.58" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="842.59" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="948.29" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="986.76" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="1047.65" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="929.82" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="911.06" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="893.58" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="877.3" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="957.42" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="967.13" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="976.7" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="996.04" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1016.3" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1036.89" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="1006.09" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="1026.57" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="1113.5" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="100.34" y="1161.51" width="56.65" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1226.83" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1125.63" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="1091" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1079.41" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1102.59" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="1068.85" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1058.23" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="1137.63" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1149.49" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1174.27" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="1187.36" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1200.49" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="1213.6" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="1240.42" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1254.46" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1283.48" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1313.66" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1345.56" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1379.19" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1414.62" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1451.86" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1491.63" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1521.64" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1530.46" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1539.27" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1548.09" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1566.85" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1576.66" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1585.92" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1596.61" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1617.25" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1627.69" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1638.13" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1648.82" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1666.14" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="128.67" y="1671.71" width="28.33" height="3.53"/>
		<rect fill={fillcls1} x="114.85" y="1268.83" width="42.14" height="3.53"/>
		<rect fill={fillcls1} x="100.2" y="1298.34" width="56.79" height="3.53"/>
		<rect fill={fillcls1} x="100.2" y="1329.61" width="56.79" height="3.53"/>
		<rect fill={fillcls1} x="100.2" y="1362.37" width="56.79" height="3.53"/>
		<rect fill={fillcls1} x="100.27" y="1396" width="56.79" height="3.53"/>
		<rect fill={fillcls1} x="100.27" y="1433.24" width="56.79" height="3.53"/>
		<rect fill={fillcls1} x="100.27" y="1472.13" width="56.79" height="3.53"/>
		<rect fill={fillcls1} x="100.27" y="1513.15" width="56.79" height="3.53"/>
		<rect fill={fillcls1} x="100.27" y="1557.47" width="56.79" height="3.53"/>
		<rect fill={fillcls1} x="100.27" y="1606.8" width="56.79" height="3.53"/>
		<rect fill={fillcls1} x="100.27" y="1660.58" width="56.79" height="3.53"/>
	</g>
	<g id="Blue_scale" data-name="Blue scale">
		<rect fill={fillcls2} x="193.4" y="1671.46" width="56.29" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1655.76" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1623.83" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1607.87" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1639.8" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1575.94" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1544.55" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1528.86" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1591.9" width="42.11" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1560.24" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1513.17" width="56.29" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1497.47" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1465.54" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1449.58" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1481.51" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1417.65" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1386.26" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1370.57" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1433.61" width="42.11" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1401.95" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1354.88" width="56.29" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1339.18" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1307.25" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1291.29" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1323.22" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1259.36" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1227.97" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1212.28" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1275.32" width="42.11" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1243.66" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1196.59" width="56.29" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1180.89" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1148.96" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1133" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1164.93" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1101.07" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1069.68" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1053.99" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1117.03" width="42.11" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1085.37" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1038.3" width="56.29" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1022.87" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="990.94" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="974.97" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="1006.9" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="943.04" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="911.65" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="895.96" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="959" width="42.11" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="927.35" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="880.27" width="56.29" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="864.44" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="832.51" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="816.54" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="848.48" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="784.61" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="753.23" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="737.54" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="800.58" width="42.11" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="768.92" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="721.84" width="56.29" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="706.15" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="674.22" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="658.25" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="690.19" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="626.32" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="594.94" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="579.25" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="642.29" width="42.11" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="610.63" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="563.55" width="56.29" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="547.86" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="515.93" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="499.96" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="531.9" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="468.03" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="436.65" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="420.96" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="484" width="42.11" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="452.34" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="405.26" width="56.29" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="389.57" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="357.64" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="341.67" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="373.61" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="309.74" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="278.36" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="262.67" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="325.71" width="42.11" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="294.05" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="246.97" width="56.29" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="231.28" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="199.35" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="183.38" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="215.32" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="151.45" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="120.07" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="104.38" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="167.42" width="42.11" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="135.76" width="28.33" height="3.53"/>
		<rect fill={fillcls2} x="193.4" y="88.68" width="56.29" height="3.53"/>
	</g>
	<g id="nums">
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(51.77 1679.04)">6</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(51.77 1627.69)">7</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(51.77 1572.16)">8</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(51.77 1530.46)">9</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 1488.94)">10</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 1414.62)">12</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 1317.19)">15</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 1177.8)">20</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 1061.76)">25</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 965.47)">30</text>
		<text fill={fillcls2} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(269.37 897.11)">0</text>
		<text fill={fillcls2} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(269.37 738.38)">10</text>
		<text fill={fillcls2} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(269.37 1052.95)">-10</text>
		<text fill={fillcls2} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(269.37 1210.89)">-20</text>
		<text fill={fillcls2} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(269.37 1368.97)">-30</text>
		<text fill={fillcls2} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(269.37 1527.37)">-40</text>
		<text fill={fillcls2} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(269.37 1679.04)">-50</text>
		<text fill={fillcls2} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(269.37 578.25)">20</text>
		<text fill={fillcls2} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(269.37 422.02)">30</text>
		<text fill={fillcls2} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(269.37 262.67)">40</text>
		<text fill={fillcls2} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(269.37 116.22)">50</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 877.3)">35</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 798.61)">40</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 723.86)">45</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 655.34)">50</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(25.76 589.92)">55</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(25.76 530.01)">60</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 472.44)">65</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 422.02)">70</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 362.75)">80</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(24.76 301.97)">90</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(0 238.42)">100</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(0 177.33)">
			<tspan letterSpacing={letterSpacing} >1</tspan>
			<tspan x="23.81" y="0">10</tspan>
		</text>
		<text fill={fillcls1} fontSize={fontSize3} fontFamily={fontFamily} transform="translate(0 116.22)">120</text>
	</g>
	<g id="Слой_6" data-name="Слой 6">
		<text fontFamily={fontFamily} fill={fillcls6} fontSize={fontSize6} transform="translate(33.32 49.54)">bar</text>
		<text fontFamily={fontFamily} fill={fillcls6} fontSize={fontSize6} transform="translate(214.45 49.54)">ºC</text>
	</g>
</svg>

</div>
);

export default ThermoSvg;
