import * as React from "react"

import VideoButton from "../Elements/Buttons/LayoutButtons/VideoButtons"
import ContactsButton from "../Elements/Buttons/LayoutButtons/LayoutContacts" 
import ContactButton from "../Elements/Buttons/LayoutButtons/LayoutContact"

import DarkTop from "../Modules/Top/TopDark"
import LightTop from "../Modules/Top/TopLight"
import MainPageFull from "./fullpagemain"


class MainPage extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			darkLogo: false
		}
		this.showDarkTop = this.showDarkTop.bind(this);
		this.showLightTop = this.showLightTop.bind(this);
	}

	showDarkTop() {
		this.setState(() => ({
		  darkLogo: true
		}));
	}

	showLightTop(){
		this.setState(() => ({
			darkLogo: false
		}))
	}

	render (){
		const trueDarkLogo = !this.state.darkLogo;
		return (
			<>
        <div>{ trueDarkLogo ? <LightTop /> : <DarkTop /> }</div>
        <VideoButton />
        <ContactsButton />
        <ContactButton />
        <main><MainPageFull showDarkTop={this.showDarkTop} showLightTop={this.showLightTop} /></main>		    
			</>
		  )
		
	}
}


export default MainPage;