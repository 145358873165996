import React from 'react';

import ServiceButton from '../../Icons/ElementIcons/servicebutton2.svg'


export default function LayoutContacts() {
  
	return (
        <>
                <button id="phonebtn" className="phone-learn-more">
                    <div className="phonecircle">
                        <p className="phonetext">Сервисная служба: 
                                <a href="tel:+74957214477" style={{color: "#fff", textDecoration: "none"}}>+7 (495) 72-144-77;</a>
                                <a href="tel:+74957299927" style={{color: "#fff", textDecoration: "none"}}> +7 (495) 72-999-27</a>
                        </p>
                    </div>
                    <div className="phone-button-text">
                        <img src = {ServiceButton} alt=''/>
                    </div>
                </button>
        </>
	);
  };