import React from "react"

import {Button} from "react-bootstrap"
import ContainerIcon from "../../../Icons/EquipmentIcons/08-modules.svg"

const ContainerButton = () => (

  <>
    <Button variant="eq-squared" size="xxl">
      <img src={ContainerIcon} className="equipment-btn-icon" alt="Оборудование агрегаты компрессорно испарительные" />
    </Button>
    <p style={{fontSize: "14px"}}>Модульное</p>
    <p style={{fontSize: "14px"}}>оборудование</p>
    <p style={{fontSize: "14px"}}></p>
  </>
)

export default ContainerButton