import React from "react";

const SVG = () => (
  <div className="service-icon">
    <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.2 354.33" width = "100%">
      <title>02</title>
      <ellipse class="cls-1" fill= '#e0e0e0' cx="131.25" cy="167.03" rx="93.5" ry="103.5"/>
      <ellipse class="cls-2" fill= '#fff' cx="132.25" cy="165.53" rx="38.5" ry="57"/>
      <path class="cls-1" fill= '#e0e0e0' d="M269.25,129l-41-24s31-44,82-42c34.42,1.35,53.36,7.71,66.34,20.93,13.66,13.93,19,34.07,14.77,53.12q-.28,1.33-.63,2.64a80.59,80.59,0,0,1-22.94,38.41L320.25,223h79v46h-161V233l89-82s16-16,7-30S290.25,95,269.25,129Z"/>
      <path class="cls-3" fill= '#27a4de' d="M219.25,167l-6-16h-85V276h105V172Zm-76,1h33v6h-33Zm0,11h33v6h-33Zm0,12h33v5h-33Zm0,10h33v6h-33Zm75,61h-75v-5h75Zm0-11h-75v-6h75Zm0-11h-75v-5h75Zm0-11h-75v-6h75Zm0-11h-75v-5h75Zm0-11h-39V168h39Z"/>
      <polyline class="cls-3" fill= '#27a4de' points="214.25 151.03 233.25 170.03 220.25 166.03 214.25 151.03"/>
    </svg>
      <svg version="1.1" id="service-leftarr-01" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-20 -18 50 50" fill= '#27a4de' >
          <path d="M26.485,21.206c0.429-0.444,0.429-1.143,0-1.587c-0.444-0.429-1.159-0.429-1.587,0l-8.047,8.047
          V1.111C16.851,0.492,16.359,0,15.74,0c-0.619,0-1.127,0.492-1.127,1.111v26.555l-8.031-8.047c-0.444-0.429-1.143-0.429-1.587,0
          c-0.429,0.444-0.429,1.143,0,1.587l9.952,9.952c0.429,0.429,1.143,0.429,1.587,0L26.485,21.206z"/>
      </svg>
  </div>

);

export default SVG;
