import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import CardArticle from './CardArticle';

export default function Articles() {
    return(
            <Container fluid>
                <Row>
                    <Col></Col>
                    <Col lg="5" xl="5">
                        <CardArticle 
                            src = "https://raw.githubusercontent.com/surreum/imagestore/main/refeng/cards_img/blog/blog-freons.jpg" 
                            alt= "Статья блога о запрете фреонов" 
                            title = "Запрет фреонов"
                            text = "Типы и характеристики фреонов. Их влияние на экологию и вопросы, связанные с запретом."
                            link = "/blog/zapret-freonov/"
                        />
                    </Col>
                    <Col lg="5" xl="5">
                        <CardArticle 
                            src = "https://raw.githubusercontent.com/surreum/imagestore/main/refeng/cards_img/blog/blog-ammonia.jpg" 
                            alt= "Статья блога об аммиачных холодильных установках" 
                            title = "Аммиачные холодильные установки"
                            text = "Плюсы и минусы аммиачных холодильных систем в сравнении с другими хладагентами."
                            link = "/blog/ammiachnye-holodilnye-ustanovki/"
                        />
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
    )
}