import React from "react";
import { Link } from "gatsby";
import {Container, Row, Col} from "react-bootstrap";
import FooterMenu from "../FooterMenu";
import RefWatsUp from "../../../Elements/SvgElements/OtherSvg/Refeng_whatsup.svg";


export default function FooterCopyright() {
    return(
        <Container fluid className="footer-container">
            <Row>
                <FooterMenu />
            </Row>
            <Row>
                <Col xl={1} lg={1} md={1} sm={1}></Col>
                <Col className="footeradress" xl={4} lg={4} md={4} sm={5}> 
                    <span>
                        Адрес компании: <strong style = {{fontWeight: '700'}}>г.Химки, Вашутинское шоссе, д.24, здание BOSCH, офис 325С</strong>
                    </span>
                    <span>
                        <strong style = {{fontWeight: '300'}}>+7 (495) 72-101-11</strong> <br/> с 9.00 до 19.00 в будние дни
                    </span>
                </Col>
                <Col xl={2} lg={2} md={2} className="whatsup">
                    <img src= {RefWatsUp} alt="Отправить сообщение нам на вотсап" />
                </Col>
                <Col className="footerlink" xl={4} lg={4} md={4} sm={5}>
                     © 2012-{new Date().getFullYear()},
                        {` `}
                    <Link to="/"><br/>ООО "Рефинжиниринг": <br/>холодоснабжение производственных, складских и торговых объектов.</Link>
                    <Link to="/sitemap">КАРТА САЙТА</Link>
                    <p>Сайт разработан: <a href="https://vgroshev.ru" target="_blank" rel="noopener noreferrer">"VG"</a></p>
                </Col>
                <Col xl={1} lg={1} md={1} sm={1}></Col>
            </Row>
        </Container>
    )
}